import boostStyles from './BoostLogoIcon.module.css';
import genesisStyles from './GenesisLogoIcon.module.css';
import infiniteStyles from './InfiniteLogoIcon.module.css';
import { Fragment } from 'react';
import boostImg from '../../../assets/images/harmony_logo.png';
import infiniteImg from '../../../assets/images/infinite_logo.svg';
import * as AppConstants from '../../../AppConstants';

function LogoIcon(props) {
    let styles, icons;
    switch (props.brandName) {
        case AppConstants.BOOST_BRAND:
            styles = boostStyles;
            icons=boostImg;
            break;
        case AppConstants.GENESIS_BRAND:
            styles = genesisStyles;
            break;
        case AppConstants.INFINITE_BRAND:
            styles = infiniteStyles;
            icons=infiniteImg;
            break;
        default:
            styles = boostStyles;
    }

    function onBrandCheck(e) {
        if (e === AppConstants.INFINITE_BRAND) {
            return true;
        }
        if (e === AppConstants.BOOST_BRAND) {
            return true;
        }
        return false;
    }

    return (
        <Fragment>
            {
                onBrandCheck(props.brandName) ?
                <>
                {
                    props.brandName === AppConstants.INFINITE_BRAND ? <div className={styles['header-sub-cont']}> <img id="identity-logo" className={styles['logo-img']} src={icons} alt="logo" /> <p className={styles['header-txt']} >Sign in with Boost Infinite</p> </div> : <img id="identity-logo" className={styles['logo-img']} src={icons} alt="logo" />
                }
                    </>:
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={styles['logo-img']} version="1.1" width="15" height="18" viewBox="250 149.7002844036697 30.500000000000007 36.60000000000001" xmlSpace="preserve">
                        <g transform="matrix(1 0 0 1 265.25 168)" id="xKrML82k-Nez4Q94pMZNy"  >
                            <linearGradient id="SVGID_2" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 0)" x1="55.727" y1="84.7347" x2="83.7246" y2="56.7371">
                                <stop offset="0%" style={{stopColor:"rgb(200,53,93)", stopOpacity: "1" }}/>
                                <stop offset="5.275473%" style={{stopColor:"rgb(198,57,95)",stopOpacity: "1"}} />
                                <stop offset="11.06%" style={{stopColor:"rgb(192,69,101)", stopOpacity: "1"}} />
                                <stop offset="17.080000000000002%" style={{stopColor:"rgb(180,85,110)", stopOpacity: "1"}} />
                                <stop offset="23.28%" style={{stopColor:"rgb(164,103,125)", stopOpacity: "1"}} />
                                <stop offset="29.599999999999998%" style={{stopColor:"rgb(141,121,145)", stopOpacity: "1"}} />
                                <stop offset="36.04%" style={{stopColor:"rgb(106,139,171)", stopOpacity: "1"}} />
                                <stop offset="42.43%" style={{stopColor:"rgb(9,158,204)", stopOpacity: "1"}} />
                                <stop offset="46.589999999999996%" style={{stopColor:"rgb(0,171,233)", stopOpacity: "1"}} />
                                <stop offset="73.75%" style={{stopColor:"rgb(0,162,89)", stopOpacity: "1"}} />
                                <stop offset="100%" style={{stopColor:"rgb(153,196,85)", stopOpacity: "1"}} />
                            </linearGradient>
                            <path style={{stroke: "none", strokeWidth: "1", strokeDasharray: "none", strokeLinecap: "butt", strokeDashoffset: "0", strokeLinejoin: "miter", strokeMiterlimit: "4", fill: "url(#SVGID_2)", fillRule: "nonzero", opacity: "1"}} vectorEffect="non-scaling-stroke" transform=" translate(-69.75, -73)" d="M 69.9 67.4 C 66.60000000000001 67.4 63.300000000000004 68.10000000000001 60.50000000000001 69.4 L 61.400000000000006 58.900000000000006 L 82.10000000000001 58.900000000000006 L 82.10000000000001 55.2 L 57.7 55.2 L 56.1 73.5 L 59.6 73.5 L 59.800000000000004 73.4 C 62.7 71.80000000000001 66.2 70.9 69.60000000000001 70.9 C 76.00000000000001 70.9 80.10000000000001 74.10000000000001 80.10000000000001 79 C 80.10000000000001 84.1 76.10000000000001 87.2 69.7 87.2 C 63.6 87.2 59.300000000000004 84.4 59.300000000000004 80.4 L 59.300000000000004 79.7 L 55 79.7 L 55 80.4 C 55 86.5 61 90.80000000000001 69.7 90.80000000000001 C 78.60000000000001 90.80000000000001 84.5 86.00000000000001 84.5 79.00000000000001 C 84.5 72 78.6 67.4 69.9 67.4 z" strokeLinecap="round" />
                        </g>
                    </svg>
            }
        </Fragment>
    );
}

export default LogoIcon;